<template>
    <div class="guilds-suggestions-modal">
        <BaseModal v-if="isOpen"
                   :padding="modalPadding"
                   maxWidth="500px"
                   maxHeight="600px"
                   :isClosable="false"
                   hasScrollTopShadow
                   hasScrollBottomShadow>
            <template #header>
                <h2 class="guilds-suggestions-modal__title">
                    <Translate translation-key="quests-dashboard.guilds.popularGuildsToFollow"
                               fallback="Popular Guilds to Follow" />
                </h2>
                <div class="guilds-suggestions-modal__subtitle">
                    <Translate translation-key="quests-dashboard.guilds.beforeYouStart"
                               :replacements="{number: REQUIRED_GUILDS}"
                               fallback="Before you start, follow at least :number popular Guilds!" />
                </div>
                <div class="guilds-suggestions-modal__search">
                    <SearchFilter v-model="searchInput"
                                  :placeholder="getTrans('quests-dashboard.guilds.searchGuilds', 'Search Guilds')" />
                </div>
            </template>
            <template #content>
                <div class="guilds-suggestions-modal__content">
                    <GuildsSuggestionsModalContent />
                </div>
            </template>
            <template #footer>
                <div class="guilds-suggestions-modal__actions">
                    <BaseButton :disabled="!isSelectedGuildsCountValid || isLoading"
                                :loading="isLoading"
                                @click="handleConfirm">
                        <Translate v-if="!isSelectedGuildsCountValid"
                                   translationKey="quests-dashboard.followtAtLeast"
                                   :replacements="{number: selectedGuildsCountRemaining}"
                                   fallback="Follow :number more"/>
                        <span v-else>
                            <Translate translationKey="quests-dashboard.letsGo"
                                       fallback="Let's Go" /> 🚀
                        </span>
                    </BaseButton>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script setup lang="ts">
    import {
        computed, onUnmounted, ref, watch,
    } from 'vue';
    import BaseModal from '@/js/components/BaseModal.vue';
    import { useModalActive } from '@/js/composables/useModalActive';
    import GuildsSuggestionsModalContent from '@/js/quests/dashboard/pages/guilds/components/GuildsSuggestionsModalContent.vue';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useGuildsStore } from '@/js/store/quests/dashboard/GuildsStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { followGuilds } from '@/js/quests/dashboard/services/guildFollowApi';
    import SearchFilter from '@/js/quests/dashboard/components/filters/components/SearchFilter.vue';
    import { debounce } from '@/js/pages/helpers/debounce';
    import { getTrans } from '@/js/helpers/getTrans';
    import BaseButton from '@/js/components/BaseButton.vue';

    interface Props {
        isOpen: boolean;
    }
    defineProps<Props>();
    const emit = defineEmits<{(e: 'close'): void; }>();

    const { isMobileOrTablet } = useDeviceSize();
    const guildsStore = useGuildsStore();
    const userStore = useUserStore();

    const { fetchSidebarFollowedGuilds } = userStore;

    const DEBOUNCE_DELAY = 500;
    const REQUIRED_GUILDS = 3;
    const { fetchSuggestedGuilds, resetSuggestedGuildsCurrentPage, resetSuggestedGuilds } = guildsStore;
    const selectedGuildsCountRemaining = computed(() => REQUIRED_GUILDS - guildsStore.suggestedGuilds.selected.length);
    const isSelectedGuildsCountValid = computed(() => guildsStore.suggestedGuilds.selected.length >= REQUIRED_GUILDS);
    const isLoading = ref(false);

    const modalPadding = computed(() => {
        if (isMobileOrTablet.value) {
            return '16px 0 16px';
        }

        return '24px 0 24px';
    });

    const searchInput = computed({
        get: () => guildsStore.suggestedGuilds.search,
        set: (newValue) => {
            guildsStore.suggestedGuilds.search = newValue;
        },
    });

    const handleConfirm = async () => {
        isLoading.value = true;
        try {
            await followGuilds(guildsStore.suggestedGuilds.selected);
            await fetchSidebarFollowedGuilds();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            isLoading.value = false;
            emit('close');
        }
    };

    const debouncedFetchData = debounce(async () => {
        resetSuggestedGuildsCurrentPage();
        await fetchSuggestedGuilds();
    }, DEBOUNCE_DELAY);

    watch(() => guildsStore.suggestedGuilds.search, () => {
        debouncedFetchData();
    });

    useModalActive();

    onUnmounted(() => {
        resetSuggestedGuilds();
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.guilds-suggestions-modal {
    &__title {
        color: $gray50;
        font-size: 32px;
        text-align: center;
    }

    &__subtitle {
        margin-bottom: 16px;
        color: $gray500;
        text-align: center;
    }

    &__search {
        padding: 0 16px 16px 16px;

        .dropdown-search {
            padding: 9px 14px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 0 24px 20px;

        @media (max-width: $md-breakpoint-max-width) {
            margin: 0 16px 20px;
        }

        :deep(.guild-card) {
            background-color: $brand950;
        }
    }

    &__actions {
        display: flex;
        margin-top: 16px;

        button {
            max-width: 200px;
            margin: 0 auto;
        }

        @media (max-width: $md-breakpoint-max-width) {
            padding: 0 16px;

            button {
                max-width: 100%;
            }
        }
    }
}
</style>
